import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

function Contact() {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm("service_h9mhxvs","template_y7i2u2h", form.current, 'user_o3jw2KhboyHlHGrJUr4lS');

    };
  
    return (
        <div className="contact-container">
      <form className="contact" ref={form} onSubmit={sendEmail}>
          <h2>Contact Me</h2>
        <label htmlFor="user_name">Name</label>
        <input type="text" id="user_name" name="user_name" />
        <label htmlFor="user_email">Email</label>
        <input type="email" id="user_email" name="user_email" />
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" />
        <input className='button' type="submit" value="Send" />
      </form>
      <div className='contact-text'>
        <h2>How do you get started?</h2>
        <p>It's as easy as filling out this form<br />to schedule a time for a free introductory conversation.</p>
      </div>
      </div>
    );
}

export default Contact;

